import { Box, Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import CardHeading from '../card-heading/CardHeading';

// @ts-ignore
export interface CurrentBillSnapshotProps {
  customerName: string;
  currentBill: number;
  start: string;
  end: string;
  links?: { text: string; to: string; external?: boolean }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    billText: {
      color: theme.palette.text.primary,
      fontWeight: 900,
    },
    buttonTextFix: {
      marginTop: '.15rem',
    },
    gridPadding: {
      padding: '1rem 1rem 1rem 3.5rem',
    },
    iconLink: {
      paddingLeft: '.75rem',
      paddingRight: '.75rem',
    },
  })
);

const text = {
  title: 'Current month',
  subtitle: 'Month-to-date total cost',
};

export const CurrentBillSnapshot: FunctionComponent<CurrentBillSnapshotProps> = (
  props
) => {
  const classes = useStyles();
  const subtitle = `${moment.utc(props.start).format('MMMM')} ${moment
    .utc(props.start)
    .format('D')}, ${moment.utc(props.start).format('YYYY')} - ${moment
    .utc(props.end)
    .format('MMMM')} ${moment.utc(props.end).format('D')}, ${moment
    .utc(props.start)
    .format('YYYY')}`;
  return (
    <Grid container>
      <Grid item xs className={classes.gridPadding}>
        <Box>
          <CardHeading title={text.title} subtitle={subtitle} />
          <br />
          <Typography variant="h5" className={classes.billText} noWrap>
            {`$${Number(props.currentBill).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}`}
          </Typography>
          Month-to-date total cost
          <br />
          <br />
          <Button
            startIcon={<CreditCardIcon />}
            variant="outlined"
            color="secondary"
            href="https://ignw-test.chargebee.com/d/invoices/62"
          >
            <span className={classes.buttonTextFix}>Pay Bill</span>
          </Button>
          <br />
          <br />
          <Button
            startIcon={<InfoIcon />}
            variant="outlined"
            color="primary"
            className={classes.iconLink}
            href={'/billing'}
          >
            <span className={classes.buttonTextFix}>Understand Your Costs</span>
          </Button>
          <br />
          <br />
          <Button
            startIcon={<HelpIcon />}
            variant="outlined"
            color="primary"
            href={'http://support.cisco.com'}
            className={classes.iconLink}
            target="_blank"
          >
            <span className={classes.buttonTextFix}>Get Support</span>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CurrentBillSnapshot;
