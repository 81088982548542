import { CostPerProjectChartProps } from '../charts/CostPerProjectChart';

export const costPerProjectData: CostPerProjectChartProps['data'] = [
  {
    project: 'HRMS',
    amount: 60,
  },
  {
    project: 'CC',
    amount: 66,
  },
  {
    project: 'Billing',
    amount: 44,
  },
  {
    project: 'Inventory',
    amount: 80,
  },
  {
    project: 'SAP',
    amount: 99,
  },
];
