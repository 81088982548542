import { Box, Grid } from '@material-ui/core';
import React from 'react';
import CardHeading from '../card-heading/CardHeading';
import CostPerProjectChart, {
  CostPerProjectChartProps,
} from '../charts/CostPerProjectChart';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface CostPerProjectProps {
  data: CostPerProjectChartProps['data'];
  height: number;
}

const staticText = {
  title: 'Cost Per Project',
  subtitle: 'January 2021 - February 2021',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
  })
);

export const CostPerProject: React.FC<CostPerProjectProps> = ({
  data,
  height,
}) => {
  const classes = useStyles();
  return (
    <Box flex={1} className={classes.box}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeading
            title={staticText.title}
            subtitle={staticText.subtitle}
          />
        </Grid>
        <Grid item xs={12} style={{ height }}>
          <CostPerProjectChart data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};
